import React from 'react';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';

import rootReducer from './redux/Reducer';

export const history = createBrowserHistory();
const Root = ({ children, initialState = {} }) => {

    const store = createStore(
        rootReducer(history),
        initialState,
        compose(applyMiddleware(thunk, routerMiddleware(history)))
    );

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>{children}</ConnectedRouter>
        </Provider>
    );
};

export default Root;
