import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import factory from './Factory';

const consoleLogReducer = (state = null, { type, ...action }) => {
  // To do: only log when in development mode
  console.log(type, action, state);
  return state;
}

export default history => {
  return (state, action) => consoleLogReducer(combineReducers({
    router: connectRouter(history),
    ...factory.reducers,
  })(state, action), action);
}
