import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { css } from '@emotion/css'
import Plot from 'react-plotly.js';

import { getBaseUrl } from '../../utils/Utils';
import { SmallSpinner } from '../shared/LoadingIndicator';
import { usePrevious } from '../../utils/Utils';
import { ErrorPage } from '../shared/Errors';
import pageActions from '../../redux/actions/Pages';
import { mapPagesToProps } from '../../redux/reducers/Pages';
import figureActions from '../../redux/actions/Figures';
import { mapFiguresToProps } from '../../redux/reducers/Figures';
import { Element, Leaf } from '../slate/Element';
import { LinkElement, withInlines } from '../slate/Link';
import { ImageElement } from '../slate/Image';
import Title from '../layout/Title';
import { NavbarTitle } from '../layout/Navigation';

export const ChartElement = connect(
  (state, ownProps) => ({
    ...mapFiguresToProps(state, ownProps),
  }),
)(
  ({ attributes, children, figure, slug, index }) => {
    return (
      <div {...attributes}>
        {children}
        <div
          contentEditable={false}
          className={css`
            position: relative;
            text-align: center;
          `}
        >
          {figure
            ? <div>
                <Plot
                  data={figure.figure.data}
                  layout={figure.figure.layout}
                  className={css`
                    display: inline;
                    position: absolute;
                  `}
                />
              </div>
            : <div>
                <div
                    className={css`
                      display: block;
                      position: absolute;
                      top: 0.5em;
                      left: 0.5em;
                      background-color: white;
                    `}
                >
                  <SmallSpinner />
                </div>
                <img
                  src={`${getBaseUrl()}/static/${slug}.${index}.png`}
                  className={css`
                    width: 100%;
                    max-height: 450px; 
                    max-width: 700px; 
                  `}
                />
              </div>
          }
        </div>
      </div>
    )
  }
);

const CustomElement = props =>
  <Element
    {...props}
    customElements={{
      image: ImageElement,
      'plotly-chart': props =>
        <ChartElement
          {...props}
          index={props.element && props.element.index}
          slug={props.element && props.element.slug}
        />,
      link: LinkElement,
    }} 
  />;

export default connect(
    (state, ownProps) => ({
      ...mapPagesToProps(state, ownProps),
    }),
    { ...figureActions, ...pageActions }
  )(
    ({ page, getPage, getPageIsLoading, getFigureIsLoading, getFigures, pagesList, getPagesList, getPagesListIsLoading, type, slug, ...p }) => {
      const renderElement = useCallback(CustomElement, [])
      const renderLeaf = useCallback(props => <Leaf {...props} />, [])
      //https://stackoverflow.com/questions/65852411/slate-js-throws-an-error-when-inserting-a-new-node-at-selected-region
      const editorRef = useRef()
      const prevSlug = usePrevious(slug);
      const prevType = usePrevious(type);
      if (!editorRef.current) editorRef.current = withInlines(withReact(createEditor()));
      const editor = editorRef.current
      useEffect(() => {
        // Always load page and figures on page load
        getPage({ slug, type });
        getFigures({ slug, type });
      }, [])
      
      useEffect(() => {
        if ((type !== prevType || slug !== prevSlug) && typeof prevSlug !== 'undefined') {
          getPage({ slug, type });
          getFigures({ slug, type });
        }
      }, [type, slug, prevType, prevSlug])

      // Spinner is required to reset Slate
      // https://github.com/ianstormtaylor/slate/issues/4689
      // if (getPageIsLoading || getFigureIsLoading) return <SmallSpinner />;
  
      if (!page || !page.content) return null;

      editor.children = page.content;
      return <>
        <NavbarTitle>{page.title}</NavbarTitle>
        {/* {type && <Title>{page.title}</Title>} */}
        <Slate
          editor={editor}
          value={page.content}
          className="slate-editor-margin"
          onChange={() => {}}
        >
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            autoFocus
            readOnly={true}
          />
        </Slate>
      </>
    }
  );