import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Container } from 'react-bootstrap'
import { css } from '@emotion/css'

export const Footer = ({ children, className=null }) => {
  const element = document.getElementById('footer');
  if (className !== null) {
    element.className = className;
  }
  return createPortal(children, element)
};

const NavLink = ({ to, children }) => {
  const { pathname } = useLocation();
  return (
    <Nav.Link
      as={Link}
      to={to}
      active={to === pathname}
    >
      {children}
    </Nav.Link>
  );
}

export default () => {
  return <>
    <Navbar sticky="top"  bg="light" expand="lg">
      <Container>
        <Navbar.Brand
          as={Link}
          to="/"
        >
          <img
            src="/logo192.png"
            width="30"
            height="30"
            className={css`margin-right: 10px;` + ' d-inline-block align-top'}
            alt="Bitcoin in charts"
          />
          Bitcoin in Charts
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Navbar.Text id="navbar-title" className={css`cursor: default;`} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Container className={css`margin-top: 50px;`}>
      <Outlet />
    </Container>
    <Footer className="footer mt-auto py-3 bg-dark">
      <Navbar bg="dark" expand="lg" variant="dark">
        <Container>
          <Navbar.Brand
            as={Link}
            to="/"
          >
            <img
              src="/logo192.png"
              width="30"
              height="30"
              className={css`margin-right: 10px;` + ' d-inline-block align-top'}
              alt="Bitcoin in charts"
            />
          </Navbar.Brand>
          <Nav className="me-auto">
            <NavLink to="/about">
              About
            </NavLink>
            <NavLink to="/disclaimer">
              Disclaimer
            </NavLink>
            <NavLink to="/contact">
              Contact
            </NavLink>
          </Nav>
        </Container>
      </Navbar>
    </Footer>
  </>
}

export const NavbarTitle = ({ children }) => createPortal(children, document.getElementById('navbar-title'));