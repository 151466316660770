import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { css } from '@emotion/css'

import { SmallSpinner } from '../shared/LoadingIndicator';
import { mapPagesToProps } from '../../redux/reducers/Pages';
import pageActions from '../../redux/actions/Pages';
import { getBaseUrl } from '../../utils/Utils';

const ThumbnailsGallery = connect(mapPagesToProps)(
  ({ pagesList }) => pagesList
      // Use display flex to align cards correctly: https://www.codeply.com/go/KppyJTTbwM
      // Remove gutter space for rows: https://getbootstrap.com/docs/5.0/layout/gutters/
    ? <Row className="display-flex g-0">
        {Object.values(pagesList).map(({ thumbnail, type, slug, title, description }, key) =>
          <Card
            key={key}
            className={css`
              display: inline-block;
              max-width: 400px; 
              margin: 12px;
              border: 0px;
            ` + ' float-start'}
          >
            <Link to={`/${type}/${slug}`}>
              <Card.Img variant="top" src={`${getBaseUrl()}/static/${thumbnail}`}
              className={css`
                max-height: 500px; 
                max-width: 400px; 
                box-shadow: 0 1.375rem 2.25rem -0.75rem rgb(64 64 64 / 13%);
              `}/>
            </Link>
            <Card.Body>
              <Card.Title>{title}</Card.Title>
              <Card.Text>{description}</Card.Text>
            </Card.Body>
          </Card>
        )
      }
    </Row>
    : null
);



export default connect(mapPagesToProps, pageActions)(
  ({ getPagesList, pagesList, getPagesListIsLoading, type }) => {
    useEffect(() => {
      getPagesList({ params: { type: 'analysis' } });
    }, [])

    if (getPagesListIsLoading) return <SmallSpinner />;

    return <ThumbnailsGallery type='analysis' />;
  }
);